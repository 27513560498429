import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from './response';
import 'rxjs/add/operator/map';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class OrganizationService {

	constructor(
		private http: HttpClient
		) {
	}

	async getDataAsync(url: string): Promise<ResponseData[]> {
		try {
		  const res = await this.http.get<ResponseData[]>(url).toPromise();
		  return this.extractData(res);
		} catch (error) {
		  if (error instanceof HttpErrorResponse) {
			return Promise.reject(`Server error: ${error.message}`);
		  } else {
			return Promise.reject('An unexpected error occurred.');
		  }
		}
	}

	getData(url): Promise<ResponseData[]> {
		return this.http.get(url).toPromise().then((data : any) => this.extractData(data)).catch(err => {
			return Promise.reject(err.error || 'Server error');
		});
	}

	postData(url, formData): Promise<ResponseData[]> {
		return this.http.post(url, JSON.stringify(formData)).toPromise().then((data : any) => this.extractData(data)).catch(err => {
			return Promise.reject(err.error || 'Server error');
		});
	}

	putData(url, formData): Promise<ResponseData[]> {
		return this.http.put(url, JSON.stringify(formData)).toPromise().then((data : any) => this.extractData(data)).catch(err => {
			return Promise.reject(err.error || 'Server error');
		});
	}

	deleteData(url): Promise<ResponseData[]> {
		return this.http.delete(url).toPromise().then((data : any) => this.extractData(data)).catch(err => {
			return Promise.reject(err.error || 'Server error');
		});
	}

	extractData(data) {
		return Promise.resolve(data);
	}
}